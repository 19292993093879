.custom-picker input {
  width: 0;
  visibility: hidden;
}

.custom-picker {
  padding-top: 10;
  padding-bottom: 10;
  margin-top: -10px;
}

.ant-input:disabled {
  background: rgba(0, 0, 0, 0.04) !important;
  border-color: 1px solid #d9d9d9;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.5) !important;
}

.ant-picker .ant-picker-input > input[disabled],
.ant-select-disabled .ant-select-selector {
  color: rgba(0, 0, 0, 0.5) !important;
  border-color: 1px solid #d9d9d9;
}
