.custom-dragger {
  background-color: var(--secondary-light-color) !important;
  border-color: var(--secondary-color) !important;
  width: 100%;

  display: flex;
}

.ant-upload-drag:hover {
  border-color: var(--secondary-color) !important;
}

.upload-hint-choose-file {
  color: var(--secondary-color);
  text-decoration: underline;
}

.custom-dragger .ant-upload-list {
  display: none !important;
}

.uploaded-file-message {
  margin-top: 8px;
  color: #999;
  font-size: 12px;
}
