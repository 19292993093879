.ant-descriptions-item-label {
  color: var(--secondary-color) !important;
  font-size: 12px !important;
}

.ant-descriptions .ant-descriptions-item-label::after {
  content: '';
}

.ant-descriptions-item-content {
  margin-top: -15px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
