:root {
  --primary-color: #00a6fb;
  --primary-color-light: #d0e9f5;
  --secondary-color: #07212d;
  --hint-secondary-color: #0b374d;
  --secondary-light-color: #f7fcfc;
  --hint-primary-color: #0588ca;
  --sub-heading-color: #525252;
  --border-color: #dff4f5;
  --light-grey1: #acb9bf;
  --light-grey2: #99acb5;
  --grey-text: #858585;
  --grey-heading: #4d6f80;
  --hover-color: #f2fbff;
  --selection-color: #d0e9f5;
  --important-color: #e91e1e;
  --important-highlight-color: rgb(255, 222, 222);
  --success-color: #00aa11;
  --tag-success-color: #c4edeb;
  --tag-failure-color: #ff7875;
  --black-color: #000000;
}

body {
  margin: 0;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header {
  min-height: 70px;
}

.header > .ant-card-body,
.width-100 {
  width: 100%;
}

code {
  font-family: 'Poppins' !important;
}

.ant-menu-title-content,
.label.ant-radio-button-wrapper,
.ant-table-content,
.ant-tag,
.ant-avatar,
.ant-btn,
.ant-input,
.ant-select,
.ant-select-selection-item,
.ant-steps,
.ant-badge,
.ant-collapse,
.ant-descriptions {
  font-family: 'Poppins' !important;
}

.ant-typography {
  font-family: 'Poppins' !important;
  font-weight: 400;
}

.ant-table-thead > tr > th {
  color: #787878 !important;
}

.primary-line {
  border: 0.1px dashed var(--border-color) !important;
  background-color: var(--border-color);
}
.secondary-line {
  border: 0.1px dashed #e6e6e6 !important;
  background-color: #e6e6e6;
}

.button {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: white !important;
}

.button:hover {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: white !important;
}

.button:focus {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: white !important;
}
.important-action-button {
  background-color: var(--important-color) !important;
  border-color: var(--important-color) !important;
  color: white !important;
}

.important-action-button:hover {
  background-color: var(--important-color) !important;
  border-color: var(--important-color) !important;
  color: white !important;
}

.important-action-button:focus {
  background-color: var(--important-color) !important;
  border-color: var(--important-color) !important;
  color: white !important;
}

.secondary-button {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}

.secondary-button:hover {
  background-color: var(--hint-secondary-color) !important;
  border-color: var(--hint-secondary-color) !important;
}

.plain-button:hover {
  border-color: var(--hint-primary-color) !important;
  background-color: #ffffff !important;
}

.secondary-button:focus {
  background-color: var(--hint-secondary-color) !important;
  border-color: var(--hint-secondary-color) !important;
}

.ternary-button {
  background-color: #dff4f5 !important;
  border-color: #dff4f5 !important;
  color: #2a6265 !important;
}

.ternary-button:hover {
  background-color: #c7e4e6 !important;
  border-color: #c7e4e6 !important;
  color: #2a6265 !important;
}

.ternary-button:focus {
  background-color: #c7e4e6 !important;
  border-color: #c7e4e6 !important;
  color: #2a6265 !important;
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  display: inline-block;
  color: var(--important-color);
  content: '*';
}
.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}

.display-input {
  background-color: var(--secondary-light-color) !important ;
  border-color: #f0feff !important;
  color: #222222 !important;
}

.ant-form-item .ant-form-item-label {
  font-weight: 500;
}

.onboarding-screen .ant-input,
.onboarding-screen .ant-select {
  /* border-color: #dff4f5; */
  border: 0px solid #a0a0a0;
  border-radius: 6px;

  /* Added by RB - New onboarding flow */
  height: 36px;
  min-width: 200px;
  background: #f5f6f7;
}

.onboarding-screen .ant-form-item-control-input-content > input {
  height: 36px;
}

.onboarding-screen .ant-input-affix-wrapper {
  border: 0px;
  background: #f5f6f7;
  padding-top: 0px;
  padding-bottom: 0px;
}

.onboarding-screen .ant-form-input,
.onboarding-screen .ant-form-item {
  margin-bottom: 0px;
}

.onboarding-screen .ant-input:hover,
.onboarding-screen .ant-input:focus,
.onboarding-screen .ant-input-focused,
.onboarding-screen .ant-input-affix-wrapper:hover,
.onboarding-screen .ant-input-affix-wrapper:focus,
.onboarding-screen .ant-input-affix-wrapper-focused {
  border: 0.1px solid #29bfff;
  box-shadow: 0 0 0 2px rgba(5, 215, 255, 0.1);
  border-inline-end-width: 1px;
  outline: 0;
}

.onboarding-screen
  .ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input,
.onboarding-screen
  .ant-input-affix-wrapper-status-error:not(
    .ant-input-affix-wrapper-disabled
  ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
  border: 0.1px solid #ff4d4f;
}

.ant-steps .ant-steps-item-title {
  font-size: 14px;
}

.onboarding-radio-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.onboarding-radio-button
  .ant-radio-wrapper
  .ant-radio-input:checked
  + .ant-radio-inner:after {
  background-color: white !important;
}

.onboarding-radio-button > label {
  display: flex;
  flex-direction: row-reverse;
  border: 1px solid #f5f6f7;
  padding: 14px 20px;
  background: #f5f6f7;
  border-radius: 6px;
  width: 100%;
  justify-content: space-between;
}

.onboarding-radio-button > label::after {
  display: none;
}

.onboarding-radio-button-note {
  background: #f4fbfe;
  padding: 10px;
  border-radius: 6px;
}
.onboarding-radio-button-note > .ant-typography {
  font-size: 12px;
  margin-bottom: 0px;
}

.no-margin-bottom {
  margin-bottom: 0px !important;
}

.ant-notification-notice-message {
  font-weight: 500;
}
.anticon.anticon-close-circle.ant-notification-notice-icon.ant-notification-notice-icon-error {
  margin-top: 5px;
}
/* END Added by RB - New onboarding flow */

.custom-icon {
  color: #00ff00;
}

.ant-picker {
  width: 100%;
}

form div.ant-form-item-explain .ant-form-item-explain-error:not(:first-child) {
  display: none;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 2px;
  height: 4px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: #ffffff;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #acb9bf;
  border-radius: 6px;
}

.custom-carousel .slick-dots li button {
  width: 10px;
  height: 10px;
  background-color: var(--grey-text);
  border-radius: 50%;
}

.custom-carousel .slick-dots li.slick-active button {
  background-color: var(--primary-color);
}

/* AgentRtsByStates */
.agency-rts-controls,
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.agency-rts-controls {
  margin-bottom: 24px;
  gap: 24px;
}

.agents-rts-carriers .ant-input-affix-wrapper {
  height: 32px;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.pl-24 {
  padding: 0 24px 0;
}

.my-5 {
  margin: 20px 0;
}

.pr-6,
.px-6 {
  padding-right: 6px;
}

.px-6,
.pl-6 {
  padding-left: 6px;
}

.font-12 {
  font-size: 12px;
}

.cursor-pointer {
  cursor: pointer;
}

#filters-button:disabled,
#agents-button:disabled,
.button:disabled {
  opacity: 0.7 !important;
}

#filters-button .ant-btn-loading-icon {
  color: #ffffff;
}

.filter-checkbox .ant-checkbox {
  align-self: flex-start;
  margin-top: 2px;
}

/*  */

.square {
  height: 20px;
  width: 20px;
}

.invite-admin-form .ant-form-item {
  margin-bottom: 6px;
}

.onboard-active
  .ant-menu-light.ant-menu-horizontal
  > .ant-menu-item-active::after {
  border-bottom-color: transparent;
}

.agency-chart .ant-card-body > .ant-row {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 100%;
}

.ant-drawer-header-title {
  flex-direction: row-reverse;
}
.stats-card .ant-card-body,
.stats-card .ant-statistic {
  height: 100%;
}
.stats-card .ant-statistic {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.custom-date-button:hover {
  color: unset !important;
}

.offboard-agents .ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 8px 16px !important;
}

.ant-custom-picker-popover.ant-popover-disabled-compatible-wrapper {
  min-width: 100%;
}

.ant-custom-picker-popover.ant-popover-disabled-compatible-wrapper > button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.errorModal .ant-modal-body {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.anticon-info-circle {
  color: var(--primary-color);
}

.disabled-row {
  background-color: #f5f5f5;
}

.custom-drlp-dropdown .ant-empty {
  display: none !important;
}

.override-checkbox-bg
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: #001f45 !important;
  border-color: #001f45 !important;
}
.override-checkbox-bg
  .ant-checkbox-checked.ant-checkbox-disabled
  .ant-checkbox-inner {
  /* background-color: #00a6fb !important; */
  border-color: #001f45 !important;
}
.override-checkbox-bg .ant-checkbox-checked:after {
  border: none !important;
}

.override-checkbox-bg .ant-checkbox {
  border-radius: 0 !important; /* Remove any border radius */
}

.override-checkbox-bg .ant-checkbox-inner {
  border-radius: 0 !important; /* Ensure the inner box is square */
}

.override-checkbox-bg .ant-checkbox-input {
  border-radius: 0 !important; /* Ensure the input area is square */
}

.custom-drlp-dropdown.ant-select-dropdown {
  padding: 0px !important;
}

.dropdown-background .ant-select-selector {
  background-color: #f5f6f7 !important;
}
.override-icon-size span.anticon {
  font-size: unset !important;
}

.override-icon-size span.anticon.outlined {
  font-size: 24px !important;
}
.override-icon-size span.anticon-close-circle {
  font-size: 20px !important;
}

.overide-icon-size span.anticon-phone,
.overide-icon-size span.anticon-mail {
  font-size: 12px !important;
}

.producer-onboarding-cards .ant-card-body {
  padding: 0 !important;
}
.complete-producer-profile .ant-card-body {
  padding: 22px !important;
}

.bg-collapsible-container .ant-collapse-header {
  background-color: #daf2ff !important;
}
.bg-collapsible-container .ant-collapse-content {
  background-color: #edf9ff !important;
}

.bg-collapsible-container .ant-spin-container div {
  background-color: #edf9ff !important;
}

.ownership-details .ant-radio-checked .ant-radio-inner,
.individual-onboard-v2-options .ant-radio-checked .ant-radio-inner,
.individual-onboard-v2-details .ant-radio-checked .ant-radio-inner {
  border-color: #ffffff !important;
}

.individual-onboard-v2-details .ant-switch.ant-switch-checked {
  background-color: #032b3f !important;
}

.individual-onboard-v2-options .ant-select-selector,
.individual-onboard-v2-options .ant-form-item .ant-form-item-control-input,
.individual-onboard-v2-options
  .ant-form-item
  .ant-form-item-control-input
  input,
.individual-onboard-v2-details .ant-select-selector,
.individual-onboard-v2-details .ant-form-item .ant-form-item-control-input,
.individual-onboard-v2-details
  .ant-form-item
  .ant-form-item-control-input
  .ant-picker,
.individual-onboard-v2-details
  .ant-form-item
  .ant-form-item-control-input
  input {
  height: 36px !important;
}
.individual-onboard-v2-options .ant-select-selector {
  border: 1px solid #d9d9d9 !important;
  padding-top: 3px !important;
}

.ownership-details
  .ant-radio-wrapper
  .ant-radio-input:checked
  + .ant-radio-inner:after,
.individual-onboard-v2-options
  .ant-radio-wrapper
  .ant-radio-input:checked
  + .ant-radio-inner:after,
.individual-onboard-v2-details
  .ant-radio-wrapper
  .ant-radio-input:checked
  + .ant-radio-inner:after {
  background-color: white !important;
}

.ownership-details-popover.ant-popover .ant-popover-inner {
  padding: 16px 0 !important;
  border: 1px solid #a7ceff !important;
  color: #fafdff !important;
}

.individual-onboarding-v2-layout
  .ant-checkbox-checked:hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner,
.individual-onboarding-v2-layout
  .ant-checkbox-wrapper:hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner,
.individual-onboarding-v2-layout
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: rgba(0, 31, 69, 1) !important;
  border-color: rgba(0, 31, 69, 1) !important;
}
.individual-onboarding-v2-layout .ant-checkbox-checked:after,
.individual-onboarding-v2-layout .ant-checkbox:hover::after,
.individual-onboarding-v2-layout .ant-checkbox:hover .ant-checkbox-inner {
  border-color: rgba(0, 31, 69, 1) !important;
}

.individual-onboarding-addresses {
  background-image: url('./assets/images/addressBg.png');
  background-repeat: no-repeat;
  width: 100%;
  background-size: 100% auto;
  border-radius: 8px;
  overflow: hidden;
}

.individual-onboarding-agent-onboarded {
  background-image: url('./assets/images/newAgentOnboardWelcomeBg.png');
  background-repeat: no-repeat;
  width: 100%;
  background-size: 100% auto;
  height: 100vh;
}

.font-poppins {
  font-family: 'Poppins' !important;
}
