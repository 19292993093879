.hover-card:hover {
  background-color: var(--hover-color);
  /* transition: background-color 0.2s ease; */
  border-radius: 0px;
  box-shadow: none;
}

.hover-card {
  border-radius: 0px;
}

.description-card {
  padding: 0px !important;
}
.description-card .ant-card-body {
  padding: 6px !important;
}

.filter-chip-card {
  padding: 0px !important;
}
.filter-chip-card .ant-card-body {
  padding: 2px !important;
}
