.input-field-div {
  width: 30%;
  max-width: 36%;
  min-width: 400px;
  margin-right: 30px;
  display: block;
}

.content-card {
  margin-top: 20px;
}

.content-card.onboard {
  margin-top: 8px;
}

.content-card.agent-signup .ant-card-body {
  padding: 12px 24px;
}

.input-field-div-two {
  display: flex !important;
  justify-content: space-between !important;
  margin-top: 30px;
}

.card-button {
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid var(--secondary-color);
}

@media (min-width: 1800px) and (max-width: 3500px) {
  .input-field-div {
    width: 25%;
    max-width: 425px;
    margin-right: 30px;
  }
}

@media (min-width: 1000px) and (max-width: 1800px) {
  .input-field-div {
    width: 36%;
    max-width: 400px;
    margin-right: 30px;
  }
}
