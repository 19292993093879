.heading {
  font-size: 16px !important;
  font-weight: 500;
  color: var(--secondary-color);
}

.content {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.content-two {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.content-label-box {
  flex-basis: 45%;
}

.content-box {
  flex-basis: 50%;
  padding-bottom: 0px;
}

.outer-box {
  margin-top: 20px;
}

.content-label {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #858585 !important;
}

.content-outer-box {
  display: flex;
  justify-content: flex-start;
}
