.content-card {
  margin-top: 10px;
  border: 1px solid #e6e6e6;
  border-radius: 6px 6px 0px 0px;
}

.desc-style {
  font-weight: 400 !important;
  font-size: 12px !important;
  color: var(--hint-primary-color) !important;
  margin-top: -10px;
}

.main-heading {
  font-weight: 500 !important;
  font-size: 20px !important;
  color: #000000d9 !important;
  margin-top: -10px;
}

.disabled-step {
  pointer-events: none;
  cursor: default;
}

.ant-steps-item:hover {
  background-color: inherit !important;
  color: inherit !important;
}

.spin-tip {
  font-size: 16px;
}
