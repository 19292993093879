.siderMenu .ant-menu-item:hover {
  background-color: #f2fbff !important;
  color: var(--hint-primary-color) !important;
}

.siderMenu .ant-menu-item-selected {
  background-color: var(--primary-color-light) !important;
  color: var(--hint-primary-color) !important;
}

.ant-menu-submenu-selected .ant-menu-item-selected {
  /* color: white !important; */
  color: var(--hint-primary-color) !important;
  background-color: var(--primary-color-light) important;
}

.ant-menu-submenu-selected .ant-menu-submenu-title {
  background-color: var(--primary-color) !important;
  color: white !important;
}
