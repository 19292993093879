.ant-radio-wrapper .ant-radio-input:checked + .ant-radio-inner:after {
  background-color: var(--secondary-color);
  size: large;
}

.ant-radio-checked .ant-radio-inner {
  border-color: var(--secondary-color) !important ;
}

.radio-options {
  flex-direction: row !important;
  display: flex !important;
  justify-content: space-between !important;
  width: 100% !important;
}

.ant-radio-wrapper .ant-radio-input:focus + .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio-input + .ant-radio-inner {
  border-color: --secondary-color;
}
