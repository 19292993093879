.input-labelOne .input-labelTwo {
  color: var(--main-heading-color);
  font-weight: 500;
}

.input-labelTwo::after {
  content: '*';
  color: var(--primary-color);
}

.input-field {
  border-color: var(--border-color) !important;
  margin-top: -10px;
}

.input-display {
  background-color: var(--secondary-light-color) !important;
  border-color: #f0feff;
  color: #222222 !important;
}


