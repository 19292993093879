.custom-tabs {
  display: inline-flex;
  height: 36px;
  align-items: center;
}

.custom-tabs .tab {
  height: 36px;
  width: 128px;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.custom-tabs .tab.selected-tab-active {
  background-color: #0588ca;
  color: #ffffff;
}

.custom-tabs.individual-onboarding .tab.selected-tab-active {
  background-color: rgba(0, 31, 69, 1);
  color: #ffffff;
}

.border-l-radius {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border: 2px solid #dfe9f5;
}

.custom-tabs .border-l-radius.tab.selected-tab-active,
.custom-tabs .border-r-radius.tab.selected-tab-active {
  border-color: #0588ca;
}

.custom-tabs.individual-onboarding .border-l-radius.tab.selected-tab-active,
.custom-tabs.individual-onboarding .border-r-radius.tab.selected-tab-active {
  border-color: rgba(0, 31, 69, 1);
}

.border-r-radius {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 2px solid #dfe9f5;
}
